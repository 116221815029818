import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Outlet } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { useEffect } from 'react';
import { SiteContentSidebarProvider, SiteContentSidebar } from '@shared/ui/sidebars/SiteContentSidebar';
import GlobalSidebar from '@shared/ui/sidebars/GlobalSidebar';
import { ContainerColumn, ContainerRow } from '@components/styled';
import NavSidebar from '@widgets/navigation/Sidebar';
import NavTopbar from '@widgets/navigation/Topbar';
import Footer from '@widgets/navigation/Footer';
import { useAppSelector } from '@store/Hooks';
const MinContentWidth = 1366;
const AppLayoutContainer = styled(ContainerColumn)(() => ({
    overflow: 'hidden',
}));
const SidebarWithContent = styled(ContainerRow)(() => ({
    height: 'auto',
    overflow: 'hidden',
}));
const Content = styled(ContainerRow)((props) => ({
    overflow: 'hidden',
    height: 'auto',
    backgroundColor: props.theme.customColors.surface.background,
    transition: 'transform 0.25s, width 0.25s',
}));
const ContentScrollable = styled(ContainerColumn)(() => ({
    overflow: 'auto',
    transition: 'transform 0.25s, width 0.25s',
}));
const ContentNarrowContainer = styled(ContainerColumn)(() => ({
    minWidth: MinContentWidth,
}));
const OutletContainer = styled(ContainerColumn)(() => ({
    flex: 1,
    width: 'auto',
    height: 'auto',
}));
const AuthorizedLayout = () => {
    const { i18n } = useTranslation();
    const { language: currentLanguage, isNavSidebarOpen } = useAppSelector(state => state.app);
    useEffect(() => {
        i18n.changeLanguage(currentLanguage);
    }, [currentLanguage, i18n]);
    return (_jsxs(AppLayoutContainer, { children: [_jsx(NavTopbar, {}), _jsxs(SidebarWithContent, { children: [_jsx(NavSidebar, {}), _jsx(SiteContentSidebarProvider, { children: _jsxs(Content, { children: [_jsx(ContentScrollable, { children: _jsxs(ContentNarrowContainer, { children: [_jsx(OutletContainer, { role: 'main', children: _jsx(Outlet, {}) }), _jsx(Footer, {})] }) }), _jsx(SiteContentSidebar, {})] }) }), _jsx(GlobalSidebar, {})] })] }));
};
export default AuthorizedLayout;
